'use client';
import React, { useEffect, useState } from 'react';
import { Button, Container, Grid, Typography } from '@mui/material';
import { useClientTranslation } from '@negotium/common';
import { IProductCatalog } from '@negotium/models';
import { useNotification } from '../ErrorContext';
import Link from 'next/link';
import { addProductCartShop, getRecentProducts } from '@negotium/libCustomer';
import { LoadingRectangle } from '../Skeleton';
import { useContextCartShop } from '../ContextCartShop';
import dynamic from 'next/dynamic';

type SectionRecentProductsProps = {
	lng: 'es' | 'en';
};

const CardProductHorizontal = dynamic(
	() =>
		import('@negotium/componentsCustomer').then(
			(module) => module.CardProductHorizontal
		),
	{ ssr: false }
);

export const SectionRecentProducts = ({ lng }: SectionRecentProductsProps) => {
	const { t } = useClientTranslation(lng, ['common', 'catalog']);
	const { changeCountProducts, cartShopId, saveCartShopIdLocalStorage } =
		useContextCartShop();
	const [products, setProducts] = useState<Array<IProductCatalog>>([]);
	const [loadingProducts, setLoadingProducts] = useState<boolean>(true);
	const { setErrorNotification, setSuccessNotification } = useNotification();

	useEffect(() => {
		if (loadingProducts) {
			getRecentProducts()
				.then((response) => {
					setProducts(response.docs);
					setLoadingProducts(false);
				})
				.catch(() => {
					setLoadingProducts(false);
					setErrorNotification(t('errorGetRecentProducts'));
				});
		}
	}, [loadingProducts]);

	function setWishListUserLogged(data: boolean, index: number) {
		const aux = products;
		aux[index] = {
			...aux[index],
			whishListUserLogged: data,
		};
		setProducts([...aux]);
	}

	function handlerAddProduct(storageUniqueId: string, amount: number) {
		addProductCartShop({ amount, storageUniqueId, cartId: cartShopId })
			.then((response) => {
				changeCountProducts();
				saveCartShopIdLocalStorage(response.data as string);
				setSuccessNotification(
					t('addedProductToCartShopSuccessfully', { ns: 'catalog' })
				);
			})
			.catch(() => {
				setLoadingProducts(false);
				setErrorNotification(
					t('errorToAddedProductToCartShop', { ns: 'catalog' })
				);
			});
	}

	if (loadingProducts) {
		return (
			<Container
				maxWidth='xl'
				sx={{
					background: (theme) => theme.palette.primary.light,
					pb: 2,
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} md={12}>
						<LoadingRectangle width={'20%'} height='5vh' />
					</Grid>
					{[...Array(6).keys()].map((item) => (
						<Grid item xs={12} md={4} key={item}>
							<LoadingRectangle width={'100%'} />
						</Grid>
					))}
				</Grid>
			</Container>
		);
	}

	if (products.length === 0) {
		return null;
	}

	return (
		<Container
			maxWidth={false}
			sx={{
				py: 4,
			}}
		>
			<Container
				maxWidth='xl'
				sx={{
					px: {
						xs: 0,
						md: '16px',
					},
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} marginY={2}>
						<Typography variant='TitleSection'>
							{t('recentProducts')}
						</Typography>
					</Grid>

					{products.map((product, index) => (
						<Grid item xs={12} sm={6} md={6} lg={4} key={product.productId}>
							<CardProductHorizontal
								product={product}
								lng={lng}
								onAddProduct={handlerAddProduct}
								setWishListUserLogged={(data) =>
									setWishListUserLogged(data, index)
								}
							/>
						</Grid>
					))}
					<Grid
						item
						xs={12}
						display='flex'
						justifyContent='center'
						marginTop={3}
					>
						<Link href='/catalog'>
							<Button variant='contained'>{t('seeAllProducts')}</Button>
						</Link>
					</Grid>
				</Grid>
			</Container>
		</Container>
	);
};
