'use client';
import React, { MouseEvent, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Image from 'next/image';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import {
	Drawer,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	OutlinedInput,
	useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Link from 'next/link';
import { useClientTranslation, useSessionClient } from '@negotium/common';
import { useContextGeneral } from '../ContextGeneral';
import { Login } from '../Login';
import { Register } from '../Register';
import { ForgetPassword } from '../ForgetPassword';
import { ChangePasswordDialog } from '../ChangePassword';
import { ActivateAccount } from '../ActivateAccount';
import { Search } from '@mui/icons-material';
import ThemeBasic from 'apps/customer-portal/utils/theme';
import {
	AdminSvg,
	CatalogSvg,
	FilterSvg,
	HamburgerMenu,
	HomeSvg,
	WholesalerSvg,
} from '../Svgs';
import { logout } from '@negotium/libCustomer';

type MenuLinkNavbar = {
	label: string;
	pathname: string;
	validPermission?: boolean;
	icon?: JSX.Element;
};

const settingsAuth: Array<MenuLinkNavbar> = [
	{ label: 'Mi cuenta', pathname: '/account' },
];

function Navbar({ lng }: { lng: string }) {
	const router = useRouter();
	const pathname = usePathname();

	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const { t } = useClientTranslation(lng, ['common', 'catalog']);
	const user = useSessionClient();
	// const { setErrorNotification, setSuccessNotification } = useNotification();
	const searchParams = useSearchParams();
	const [open, setOpen] = useState<boolean>(false);
	const [isScrolled, setIsScrolled] = useState<boolean>(false);

	const {
		showLogin: openLogin,
		setShowLogin: setOpenLogin,
		handlerOpenMobileFilter,
	} = useContextGeneral();
	const [openRegister, setOpenRegister] = useState<boolean>(false);
	const [openForgetPassword, setOpenForgetPassword] = useState<boolean>(false);
	const [openActivateAccount, setOpenActivateAccount] = useState<null | {
		email: string;
		code: string;
	}>(null);
	const [openChangePassword, setOpenChangePassword] = useState<null | {
		email: string;
		code: string;
	}>(null);

	const isMobile = useMediaQuery('(max-width:768px)');

	const [productName, setProductName] = useState<string>('');

	const isVisible = (permissions: string) => {
		// return true;
		return user?.permissions.includes(permissions);
	};

	const pages: Array<MenuLinkNavbar> = [
		{
			label: 'home',
			pathname: `/${lng}`,
			validPermission: true,
			icon: <HomeSvg color={ThemeBasic.palette.secondary.main} />,
		},
		{
			label: 'catalog',
			pathname: `/${lng}/catalog`,
			validPermission: true,
			icon: <CatalogSvg color={ThemeBasic.palette.secondary.main} />,
		},
		{
			label: 'wholesaler',
			pathname: `/${lng}/catalog/wholesaler`,
			validPermission: isVisible('isWholesaler'),
			icon: <WholesalerSvg color={ThemeBasic.palette.secondary.main} />,
		},
	];

	const toggleDrawer = (newOpen: boolean) => () => {
		setOpen(newOpen);
	};

	const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};
	const handleLogout = async () => {
		// logout()
		// 	.then(() => {
		// 		setSuccessNotification(t('sessionClosedSuccessfully'));
		// 		router.push(`/${lng}`);
		// 	})
		// 	.catch(() => {
		// 		setErrorNotification(t('sessionClosedError'));
		// 	});
		await logout();
		handleCloseUserMenu();
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	function handlerClickFilter() {
		if (isMobile) {
			handlerOpenMobileFilter();
		}
		router.push(`/${lng}/catalog`);
	}

	useEffect(() => {
		const isActivateAccount: string | null =
			searchParams.get('activateAccount');
		if (isActivateAccount) {
			const code: string | null = searchParams.get('code');
			const email: string | null = searchParams.get('email');
			setOpenActivateAccount({
				email: email || '',
				code: code || '',
			});
		}
		const isChangePassword: string | null = searchParams.get('changePassword');
		if (isChangePassword) {
			const code: string | null = searchParams.get('code');
			const email: string | null = searchParams.get('email');
			setOpenChangePassword({
				email: email || '',
				code: code || '',
			});
		}
	}, [searchParams.size]);

	useEffect(() => {
		const handleScroll = () => {
			setIsScrolled(window.scrollY > 0);
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const handleMouseDownSearch = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	const handleMouseUpSearch = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const handlerClickSearch = () => {
		if (productName) {
			router.push(`/${lng}/catalog?productName=${productName}`);
		}
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			router.push(`/${lng}/catalog?productName=${productName}`);
		}
	};

	function renderMenuMobile() {
		return (
			<Box
				sx={{
					display: { xs: 'flex', md: 'none' },
					justifyContent: 'flex-start',
					width: '10%',
				}}
			>
				<Button
					onClick={toggleDrawer(true)}
					sx={{
						p: 0,
						justifyContent: 'flex-start',
						ml: {
							xs: '2px',
							md: 0,
						},
					}}
				>
					<HamburgerMenu />
				</Button>
				<Drawer
					open={open}
					onClose={toggleDrawer(false)}
					sx={{
						zIndex: 99999,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
					PaperProps={{
						sx: {
							width: '80%',
							background: (theme) => theme.palette.secondary.main,
							p: 2,
						},
					}}
				>
					<Box>
						<Box
							display='flex'
							justifyContent='space-between'
							alignItems='center'
						>
							<Box
								sx={{
									width: { xs: '30%', md: '25%' },
									ml: 0.5,
									mt: 0.5,
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Link href={`/${lng}`} hrefLang={lng}>
									<Image
										src='/static/logo-white667x218.png'
										width={667}
										height={128}
										alt={process.env.NEXT_PUBLIC_NAME || ''}
										style={{ width: '100%', height: 'auto' }}
									/>
								</Link>
							</Box>
							<Button
								onClick={toggleDrawer(false)}
								sx={{
									p: 0,
									justifyContent: 'flex-end',
								}}
							>
								<CloseIcon
									fontSize='medium'
									sx={{
										color: (theme) => theme.palette.white?.main || 'white',
									}}
								/>
							</Button>
						</Box>

						<List
							sx={{
								width: '100%',
								mt: 5,
							}}
						>
							{pages.map(
								(page) =>
									page.validPermission && (
										<ListItem
											onClick={toggleDrawer(false)}
											key={page.pathname}
											alignItems='center'
											sx={{
												width: '100%',
												height: '45px',
												pl: 0,
											}}
										>
											<Link
												href={page.pathname}
												style={{
													textDecoration: 'none',
													display: 'flex',
													alignItems: 'center',
												}}
											>
												<ListItemIcon
													sx={{
														mr: 1,
														minWidth: '35px',
														minHeight: '35px',
														background: (theme) => theme.palette.white?.main,
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													{page.icon}
												</ListItemIcon>
												<Typography
													variant='ExplicationTextBold'
													fontSize='clamp(1rem,3vw,1.2rem)'
													textAlign={{ md: 'right', xs: 'center' }}
													margin={0}
													color={(theme) =>
														theme.palette.white?.main || 'white'
													}
												>
													{t(page.label)}
												</Typography>
											</Link>
										</ListItem>
									)
							)}

							{isVisible('adminPanel') && (
								<ListItem
									sx={{
										width: '100%',
										height: '45px',
										pl: 0,
									}}
								>
									<Link
										href={`${process.env.NEXT_PUBLIC_DOMAIN_ADMIN}/${lng}/admin`}
										style={{
											textDecoration: 'none',
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<ListItemIcon
											sx={{
												mr: 1,
												minWidth: '35px',
												minHeight: '35px',
												background: (theme) => theme.palette.white?.main,
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												borderRadius: '50%',
											}}
										>
											<AdminSvg color={ThemeBasic.palette.secondary.main} />
										</ListItemIcon>
										<Typography
											variant='ExplicationTextBold'
											fontSize='clamp(1rem,3vw,1.2rem)'
											textAlign={{ md: 'right', xs: 'center' }}
											margin={0}
											color={(theme) => theme.palette.white?.main || 'white'}
										>
											{t('admin')}
										</Typography>
									</Link>
								</ListItem>
							)}
						</List>
					</Box>
				</Drawer>
			</Box>
		);
	}

	function renderMenuDesktop() {
		return (
			<Box
				display='flex'
				justifyContent='space-around'
				alignItems='center'
				sx={{
					display: { xs: 'none', md: 'flex' },
				}}
			>
				<List
					sx={{
						display: 'flex',
					}}
				>
					{pages.map(
						(page) =>
							page.validPermission && (
								<Link
									href={`${page.pathname}`}
									key={page.pathname}
									style={{
										textDecoration: 'none',
									}}
								>
									<ListItemButton
										className='ListItemButton'
										selected={page.pathname === pathname}
									>
										{t(page.label)}
									</ListItemButton>
								</Link>
							)
					)}
				</List>
				<Box sx={{ flexGrow: 0, alignItems: 'center' }}>
					<Button
						title={user ? t('menuUser') : t('registerOrLogin')}
						onClick={
							user ? (e) => handleOpenUserMenu(e) : () => setOpenLogin(true)
						}
						variant='ButtonNavbarAction'
					>
						<AccountCircleIcon />
					</Button>

					<Menu
						sx={{ mt: '45px' }}
						id='menu-appbar'
						anchorEl={anchorElUser}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorElUser)}
						onClose={handleCloseUserMenu}
					>
						{isVisible('adminPanel') && (
							<MenuItem
								onClick={() => {
									router.push(
										`${process.env.NEXT_PUBLIC_DOMAIN_ADMIN}/backoffice/es`
									);
									handleCloseUserMenu();
								}}
							>
								<Typography textAlign='center'>
									{t('admin', { ns: 'common' })}
								</Typography>
							</MenuItem>
						)}
						{user &&
							settingsAuth.map((setting) => {
								return (
									<MenuItem
										key={setting.pathname}
										onClick={() => {
											router.push(setting.pathname);
											handleCloseUserMenu();
										}}
									>
										<Typography textAlign='center'>{setting.label}</Typography>
									</MenuItem>
								);
							})}
						{user ? (
							<MenuItem
								onClick={() => {
									handleLogout();
									handleCloseUserMenu();
								}}
							>
								<Typography textAlign='center'>
									{t('logout', { ns: 'common' })}
								</Typography>
							</MenuItem>
						) : null}
					</Menu>
				</Box>
			</Box>
		);
	}
	const navbarStyles = {
		position: isScrolled ? 'fixed' : 'relative',
		top: isScrolled ? 0 : 'auto',
		left: 0,
		width: '100%',
		zIndex: 1000,
	};

	return (
		<AppBar
			position='static'
			sx={{
				minHeight: '8vh',
				py: 1,
				background: (theme) => theme.palette.secondary?.main,
				...(isScrolled && {
					...navbarStyles,
				}),
			}}
		>
			<Container maxWidth='xl'>
				<Toolbar
					disableGutters
					sx={{
						justifyContent: 'space-between',
						height: '100%',
						display: 'flex',
						justifyItems: 'center',
						flexWrap: 'wrap',
					}}
				>
					{renderMenuMobile()}

					<Box
						sx={{
							width: { xs: '25%', md: '10%' },
							minHeight: '8vh',
							display: 'flex',
							alignItems: 'center',
							position: 'relative',
						}}
					>
						<Link
							href={`/${lng}`}
							hrefLang={lng}
							style={{
								height: '100%',
								width: '100%',
							}}
						>
							<Image
								src='/static/logo-white667x218.png'
								width={667}
								height={128}
								alt={process.env.NEXT_PUBLIC_NAME || ''}
								style={{ width: '100%', height: 'auto' }}
							/>
						</Link>
					</Box>
					<Box
						sx={{
							flexGrow: 0,
							alignItems: 'center',
							display: {
								md: 'none',
								xs: 'block',
							},
							mr: {
								xs: '2px',
								md: 0,
							},
						}}
					>
						<Button
							title={user ? t('menuUser') : t('registerOrLogin')}
							onClick={
								user
									? () => router.push(`/${lng}/account`)
									: () => setOpenLogin(true)
							}
							variant='ButtonNavbarAction'
						>
							<AccountCircleIcon />
						</Button>
					</Box>
					{!pathname.includes('account') && (
						<Box
							sx={{
								width: { md: 'auto', xs: '100%' },
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								mt: { xs: 2, md: 0 },
							}}
						>
							<OutlinedInput
								placeholder={t('search', { ns: 'catalog' })}
								value={productName}
								onChange={(e) => setProductName(e.target.value)}
								onKeyDown={handleKeyDown}
								endAdornment={
									<InputAdornment position='end'>
										<IconButton
											sx={{
												zIndex: 999,
											}}
											aria-label={t('executeSearch', { ns: 'catalog' })}
											onClick={handlerClickSearch}
											onMouseDown={handleMouseDownSearch}
											onMouseUp={handleMouseUpSearch}
											edge='end'
										>
											<Search
												sx={{
													color: (theme) => theme.palette.secondary?.main,
												}}
											/>
										</IconButton>
									</InputAdornment>
								}
								inputProps={{
									sx: {
										color: ThemeBasic.palette.secondary?.main,
										zIndex: 999,
										border: 'none',
										height: { xs: '15px', md: 'auto' },
										paddingY: { xs: 2, md: '16.5px' },
										// change color placeholder
										'&::placeholder': {
											color: ThemeBasic.palette.secondary?.main,
										},
									},
								}}
								sx={{
									borderRadius: 4,
									border: 'none',
									width: { md: '450px', xs: '100%' },
									'& .MuiOutlinedInput-notchedOutline': {
										borderWidth: 0,
										background: (theme) => theme.palette.white?.main,
									},

									'& .MuiInputBase-input': {
										pl: `25px !important`,
									},
									'& .MuiInputLabel-root': {
										color: `white !important`,
									},
									'& .MuiInputLabel-shrink': {
										display: `none !important`,
									},
									'& > *': {
										borderRadius: `16px !important`,
									},
								}}
							/>

							<Button
								variant='contained'
								color='primary'
								onClick={() => {
									handlerClickFilter();
								}}
								sx={{
									ml: 1,
									p: '2px',
									minWidth: { md: '56px', xs: '44px' },
									height: { md: '56px', xs: '44px' },
									borderRadius: 4,
									background: (theme) => theme.palette.white?.main,
								}}
							>
								<FilterSvg color={ThemeBasic.palette.secondary?.main} />
							</Button>
						</Box>
					)}

					{renderMenuDesktop()}
				</Toolbar>
			</Container>
			{openLogin && (
				<Login
					open={openLogin}
					onClose={() => setOpenLogin(false)}
					lng={lng as 'es' | 'en'}
					onOpenRegister={() => {
						setOpenRegister(true);
						setOpenLogin(false);
					}}
					onOpenForgetPassword={() => {
						setOpenForgetPassword(true);
						setOpenLogin(false);
					}}
				/>
			)}

			{openRegister && (
				<Register
					open={openRegister}
					onClose={() => setOpenRegister(false)}
					lng={lng as 'es' | 'en'}
					onOpenLogin={() => {
						setOpenLogin(true);
						setOpenRegister(false);
					}}
					onOpenActivateAccount={(email) => {
						setOpenActivateAccount({
							email: email || '',
							code: '',
						});
					}}
				/>
			)}
			{openForgetPassword && (
				<ForgetPassword
					onOpenChangePassword={(email) => {
						setOpenForgetPassword(false);
						setOpenChangePassword({
							email: email || '',
							code: '',
						});
					}}
					onOpenLogin={() => {
						setOpenLogin(true);
						setOpenForgetPassword(false);
					}}
					open={openForgetPassword}
					onClose={() => setOpenForgetPassword(false)}
					lng={lng as 'es' | 'en'}
				/>
			)}
			{openChangePassword && (
				<ChangePasswordDialog
					open={!!openChangePassword}
					onClose={() => {
						router.push('/');
						setOpenChangePassword(null);
					}}
					lng={lng as 'es' | 'en'}
					info={openChangePassword}
					onOpenLogin={() => {
						setOpenLogin(true);
						setOpenChangePassword(null);
					}}
				/>
			)}
			{openActivateAccount && (
				<ActivateAccount
					open={!!openActivateAccount}
					onClose={() => {
						router.push('/');
						setOpenActivateAccount(null);
					}}
					lng={lng as 'es' | 'en'}
					onOpenLogin={() => {
						setOpenLogin(true);
						setOpenActivateAccount(null);
					}}
					info={openActivateAccount}
				/>
			)}
		</AppBar>
	);
}
export default Navbar;
