'use client';
import {
	Button,
	Dialog,
	DialogContent,
	FormGroup,
	Grid,
	InputLabel,
	Slide,
	TextField,
	useTheme,
	Box,
	Typography,
	DialogTitle,
	Checkbox,
	FormControlLabel,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useState } from 'react';
import { useNotification } from '../ErrorContext';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useClientTranslation } from '@negotium/common';
import { IWholesalerRequestSave } from '@negotium/models';
import Image from 'next/image';
import useWholesalerRequestFormData from './hooks/useWholesalerRequestFormData';
import { v4 } from 'uuid';
import { createWholesalerRequest } from '@negotium/libCustomer';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

type ModalRequestWholesalerProps = {
	open: boolean;
	onClose: () => void;
	lng: 'es' | 'en';
};

export const ModalRequestWholesaler = ({
	open,
	onClose,
	lng = 'es',
}: ModalRequestWholesalerProps) => {
	const { t } = useClientTranslation(lng, ['wholesalerRequest']);

	const theme = useTheme();

	const [loading, setLoading] = useState(false);
	const { setErrorNotification, setSuccessNotification } = useNotification();
	const initialValues = {
		wholesalerRequestId: v4(),
		companyName: '',
		companyEmail: '',
		companyPhone: '',
		companyAddress: '',
		deliveryLocation: '',
		ownTransport: false,
		transportService: false,
	};

	const onSubmit = async (values: IWholesalerRequestSave): Promise<void> => {
		setLoading(true);
		createWholesalerRequest(values)
			.then(() => {
				setLoading(false);
				onClose();
				setSuccessNotification(t('successfullyToSendWholesalerRequest'));
			})
			.catch((error) => {
				setLoading(false);
				setErrorNotification(
					error.message || t('errorToSendWholesalerRequest')
				);
			});
	};

	function handlerClose() {
		setLoading(false);
		formik.resetForm();
		onClose();
	}

	const { formik, isLoading } = useWholesalerRequestFormData(
		initialValues,
		onSubmit,
		lng
	);

	function handlerSelectTransport(e: React.ChangeEvent<HTMLInputElement>) {
		const { name } = e.target;
		if (name === 'ownTransport') {
			formik.setFieldValue('ownTransport', true);
			formik.setFieldValue('transportService', false);
		} else {
			formik.setFieldValue('ownTransport', false);
			formik.setFieldValue('transportService', true);
		}
	}

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={handlerClose}
			aria-describedby='alert-dialog-slide-description'
			PaperProps={{
				sx: {
					backgroundColor: theme.palette.white?.main || 'white',
					p: { md: 6, xs: 1 },
					borderRadius: 6,
				},
			}}
			slotProps={{
				backdrop: {
					sx: {
						backdropFilter: 'blur(10px)',
					},
				},
			}}
		>
			<DialogTitle
				sx={{
					py: 0,
				}}
			>
				<Box display='flex' justifyContent='flex-end'>
					<Button
						onClick={() => handlerClose()}
						variant='contained'
						color='secondary'
						sx={{
							p: 0,
							minWidth: '30px',
							height: '30px',
							borderRadius: '50%',
						}}
					>
						<Close />
					</Button>
				</Box>
			</DialogTitle>
			<DialogContent>
				<form onSubmit={formik.handleSubmit}>
					<Grid
						container
						marginTop={{ md: 2, xs: 0 }}
						rowSpacing={{ md: 2, xs: 1 }}
						columnSpacing={1}
					>
						<Grid item xs={12}>
							<Box sx={{ width: '10%', margin: 'auto' }}>
								<Image
									src='/static/favicon/128x128.png'
									width={100}
									height={100}
									alt={process.env.NEXT_PUBLIC_NAME || ''}
									style={{ width: '100%', height: 'auto' }}
								/>
							</Box>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='Subtitle' textAlign='center' fontWeight=''>
								{t('requestWholesaler')}
							</Typography>
							<Typography
								variant='ExplicationText'
								textAlign='center'
								fontWeight=''
							>
								{t('requestWholesalerDescription')}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<FormGroup>
								<InputLabel
									sx={{
										color: theme.palette.secondary.main || 'black',
										fontWeight: '600',
										ml: 3,
										fontSize: 'clamp(13px,3vw,1rem)',
									}}
								>
									{t('companyName')}
								</InputLabel>
								<TextField
									id='companyName'
									placeholder='Relsa...'
									value={formik.values.companyName}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={
										formik.touched.companyName &&
										Boolean(formik.errors.companyName)
									}
									helperText={
										formik.touched.companyName &&
										formik.errors.companyName &&
										t(String(formik.errors.companyName))
									}
									fullWidth={false}
									margin='dense'
									color='secondary'
									sx={{
										borderRadius: 4,
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: (theme) => theme.palette.primary.dark,
											borderWidth: 2,
										},
										'& .MuiInputBase-input': {
											pl: `25px !important`,
										},
										'& > *': {
											borderRadius: `16px !important`,
										},
									}}
								/>
							</FormGroup>
						</Grid>{' '}
						<Grid item xs={12} sm={6}>
							<FormGroup>
								<InputLabel
									sx={{
										color: theme.palette.secondary.main || 'black',
										fontWeight: '600',
										ml: 3,
										fontSize: 'clamp(13px,3vw,1rem)',
									}}
								>
									{t('companyEmail')}
								</InputLabel>
								<TextField
									id='companyEmail'
									placeholder='relsa@example.com'
									value={formik.values.companyEmail}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={
										formik.touched.companyEmail &&
										Boolean(formik.errors.companyEmail)
									}
									helperText={
										formik.touched.companyEmail &&
										formik.errors.companyEmail &&
										t(String(formik.errors.companyEmail))
									}
									fullWidth={false}
									margin='dense'
									color='secondary'
									sx={{
										borderRadius: 4,
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: (theme) => theme.palette.primary.dark,
											borderWidth: 2,
										},
										'& .MuiInputBase-input': {
											pl: `25px !important`,
										},
										'& > *': {
											borderRadius: `16px !important`,
										},
									}}
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormGroup>
								<InputLabel
									sx={{
										color: theme.palette.secondary.main || 'black',
										fontWeight: '600',
										fontSize: 'clamp(13px,3vw,1rem)',
										ml: 3,
									}}
								>
									{t('companyPhone')}
								</InputLabel>
								<TextField
									id='companyPhone'
									placeholder='+535XXXXXXX'
									value={formik.values.companyPhone}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									error={
										formik.touched.companyPhone &&
										Boolean(formik.errors.companyPhone)
									}
									helperText={
										formik.touched.companyPhone &&
										formik.errors.companyPhone &&
										t(String(formik.errors.companyPhone))
									}
									fullWidth={false}
									margin='dense'
									color='secondary'
									sx={{
										borderRadius: 4,
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: (theme) => theme.palette.primary.dark,
											borderWidth: 2,
										},
										'& .MuiInputBase-input': {
											pl: `25px !important`,
										},
										'& > *': {
											borderRadius: `16px !important`,
										},
									}}
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={12}>
							<FormGroup>
								<InputLabel
									sx={{
										color: theme.palette.secondary.main || 'black',
										fontWeight: '600',
										ml: 3,
										fontSize: 'clamp(13px,3vw,1rem)',
									}}
								>
									{t('companyAddress')}
								</InputLabel>
								<TextField
									id='companyAddress'
									placeholder='Plaza de...'
									value={formik.values.companyAddress}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									multiline
									rows={2}
									error={
										formik.touched.companyAddress &&
										Boolean(formik.errors.companyAddress)
									}
									helperText={
										formik.touched.companyAddress &&
										formik.errors.companyAddress &&
										t(String(formik.errors.companyAddress))
									}
									fullWidth={false}
									margin='dense'
									color='secondary'
									sx={{
										borderRadius: 4,
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: (theme) => theme.palette.primary.dark,
											borderWidth: 2,
										},
										'& .MuiInputBase-input': {
											pl: { md: `12px !important`, xs: '10px !important' },
										},
										'& > *': {
											borderRadius: `16px !important`,
										},
									}}
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={12}>
							<FormGroup>
								<InputLabel
									sx={{
										color: theme.palette.secondary.main || 'black',
										fontWeight: '600',
										ml: 3,
										fontSize: 'clamp(13px,3vw,1rem)',
									}}
								>
									{t('deliveryLocation')}
								</InputLabel>
								<TextField
									id='deliveryLocation'
									placeholder='Avenida 51 ...'
									value={formik.values.deliveryLocation}
									onChange={(e) => formik.handleChange(e)}
									onBlur={formik.handleBlur}
									multiline
									rows={2}
									error={
										formik.touched.deliveryLocation &&
										Boolean(formik.errors.deliveryLocation)
									}
									helperText={
										formik.touched.deliveryLocation &&
										formik.errors.deliveryLocation &&
										t(String(formik.errors.deliveryLocation))
									}
									fullWidth={false}
									margin='dense'
									color='secondary'
									sx={{
										borderRadius: 4,
										'& .MuiOutlinedInput-notchedOutline': {
											borderColor: (theme) => theme.palette.primary.dark,
											borderWidth: 2,
										},
										'& .MuiInputBase-input': {
											pl: { md: `12px !important`, xs: '10px !important' },
										},
										'& > *': {
											borderRadius: `16px !important`,
										},
									}}
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormGroup>
								<FormControlLabel
									label={t('ownTransport')}
									control={
										<Checkbox
											color='secondary'
											id='ownTransport'
											name='ownTransport'
											checked={formik.values.ownTransport}
											onChange={handlerSelectTransport}
											inputProps={{ 'aria-label': 'controlled' }}
										/>
									}
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={12} sm={6}>
							<FormGroup>
								<FormControlLabel
									label={t('transportService')}
									control={
										<Checkbox
											color='secondary'
											id='transportService'
											name='transportService'
											checked={formik.values.transportService}
											onChange={handlerSelectTransport}
											inputProps={{ 'aria-label': 'controlled' }}
										/>
									}
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={12} display='flex' justifyContent='center' mt={3}>
							<Button
								color='error'
								variant='contained'
								size='medium'
								title={t('cancel', { ns: 'common' })}
								disabled={isLoading || loading}
								onClick={() => handlerClose()}
								sx={{
									width: '200px',
									textTransform: 'none',
								}}
							>
								{t('cancel', { ns: 'common' })}
							</Button>
							<LoadingButton
								type='submit'
								autoFocus
								loading={loading || isLoading}
								loadingPosition='end'
								sx={{
									ml: 1,
								}}
								variant='ButtonAcceptDialog'
								disabled={isLoading || loading}
							>
								{t('confirm', { ns: 'common' })}
							</LoadingButton>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	);
};
