'use client';
import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import { useNotification } from '../ErrorContext';
import { Logout } from '@mui/icons-material';
import { usePathname } from 'next/navigation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	ListItem,
	Typography,
} from '@mui/material';
import Link from 'next/link';
import { useClientTranslation, useSessionClient } from '@negotium/common';

import ThemeBasic from 'apps/customer-portal/utils/theme';
import {
	CartShop,
	Password,
	RecipientsSvg,
	UserSvg,
	WishListSvg,
} from '../Svgs';
import { logout } from '@negotium/libCustomer';

export default function MenuProfile({ lng }: { lng: 'es' | 'en' }) {
	const { t } = useClientTranslation(lng, ['common']);
	// const router = useRouter();
	const [expanded, setExpanded] = React.useState<boolean>(false);
	const pathname = usePathname();

	const user = useSessionClient();

	const handleLogout = async () => {
		await logout();
	};

	const data: Array<{
		label: string;
		icon: JSX.Element;
		isLink: boolean;
		path?: string;
		pathCheck?: string;
		handlerClick?: () => void;
		validatePermission?: boolean;
	}> = [
		{
			label: 'profile',
			icon: <UserSvg color={ThemeBasic.palette.secondary.main || ''} />,
			isLink: true,
			path: '/account',
			pathCheck: `/${lng}/account`,
		},
		{
			label: 'wishList',
			icon: <WishListSvg color={ThemeBasic.palette.secondary.main || ''} />,
			isLink: true,
			path: '/account/wishList',
			pathCheck: `/${lng}/account/wishList`,
		},
		{
			label: 'myRecipients',
			icon: <RecipientsSvg color={ThemeBasic.palette.secondary.main || ''} />,
			isLink: true,
			path: '/account/myRecipients',
			pathCheck: `/${lng}/account/myRecipients`,
		},
		{
			label: 'orderHistory',
			icon: <CartShop color={ThemeBasic.palette.secondary.main || ''} />,
			isLink: true,
			path: '/account/orderHistory',
			pathCheck: `/${lng}/account/orderHistory`,
		},

		{
			label: 'changePassword',
			icon: <Password color={ThemeBasic.palette.secondary.main || ''} />,
			isLink: true,
			path: '/account/changePassword',
			pathCheck: `/${lng}/account/changePassword`,
		},

		{
			label: 'logout',
			icon: <Logout fontSize='small' color='secondary' />,
			isLink: false,
			handlerClick: handleLogout,
		},
	];

	const RenderMenu = (
		<List
			sx={{
				background: (theme) => theme.palette.white?.main || '',
				borderBottomLeftRadius: '10px',
				borderBottomRightRadius: '10px',
			}}
		>
			{data.map((item) => {
				return item.isLink ? (
					<Link
						key={item.label}
						href={item.path || ''}
						style={{
							textDecoration: 'none',
						}}
					>
						<ListItem
							key={item.label}
							title={t(item.label)}
							sx={{
								pl: 5,

								backgroundColor: (theme) =>
									theme.palette[
										item.pathCheck === pathname ? 'secondary' : 'white'
									]?.main || '',
								color: (theme) =>
									theme.palette[
										item.pathCheck === pathname ? 'white' : 'secondary'
									]?.main || '',
								svg: {
									stroke: (theme) =>
										theme.palette[
											item.pathCheck === pathname ? 'white' : 'secondary'
										]?.main,
									fill: (theme) =>
										theme.palette[
											item.pathCheck === pathname ? 'white' : 'secondary'
										]?.main,
								},

								':hover': {
									backgroundColor: (theme) => theme.palette.primary.main,
									color: (theme) => theme.palette.white?.main || '',
									svg: {
										stroke: (theme) => theme.palette.secondary.main,
										fill: (theme) => theme.palette.white?.main || '',
									},
								},
							}}
						>
							<ListItemIcon
								sx={{
									color:
										item.pathCheck === pathname ? 'white !important' : 'black',
									svg: {
										fill: (theme) => theme.palette.secondary.main,
									},
								}}
							>
								{item.icon}
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										variant='ExplicationTextBold'
										textTransform='initial'
										margin='0'
										sx={{
											color: (theme) =>
												theme.palette[
													item.pathCheck === pathname ? 'white' : 'secondary'
												]?.main || '',
										}}
									>
										{t(item.label)}
									</Typography>
								}
							/>
						</ListItem>
					</Link>
				) : (
					<ListItem
						key={item.label}
						title={t(item.label)}
						onClick={item.handlerClick}
						sx={{
							cursor: 'pointer',
							pl: 5,
							backgroundColor: (theme) =>
								theme.palette[
									item.pathCheck === pathname ? 'secondary' : 'white'
								]?.main || '',
							color: (theme) =>
								theme.palette[
									item.pathCheck === pathname ? 'white' : 'secondary'
								]?.main || '',
							svg: {
								stroke: (theme) =>
									theme.palette[
										item.pathCheck === pathname ? 'white' : 'secondary'
									]?.main,
								fill: (theme) =>
									theme.palette[
										item.pathCheck === pathname ? 'white' : 'secondary'
									]?.main,
							},

							':hover': {
								backgroundColor: (theme) => theme.palette.primary.main,
								color: (theme) => theme.palette.secondary.main,
								svg: {
									stroke: (theme) => theme.palette.secondary.main,
									fill: (theme) => theme.palette.secondary.main,
								},
							},
						}}
					>
						<ListItemIcon
							sx={{
								color:
									item.pathCheck === pathname ? 'white !important' : 'black',
								svg: {
									fill: (theme) => theme.palette.secondary.main,
								},
								':hover': {
									color: 'white !important',
									svg: {
										fill: (theme) => theme.palette.secondary.main,
									},
								},
							}}
						>
							{item.icon}
						</ListItemIcon>
						<ListItemText
							primary={
								<Typography
									variant='ExplicationTextBold'
									textTransform='initial'
									margin='0'
									sx={{
										color: (theme) =>
											theme.palette[
												item.pathCheck === pathname ? 'white' : 'secondary'
											]?.main || '',
										':hover': {
											color: (theme) =>
												theme.palette[
													item.pathCheck === pathname ? 'primary' : 'secondary'
												].main,
										},
									}}
								>
									{t(item.label)}
								</Typography>
							}
						/>
					</ListItem>
				);
			})}
		</List>
	);

	const RenderDesktop = (
		<List
			component='nav'
			disablePadding
			sx={{
				display: {
					md: 'block',
					xs: 'none',
				},
			}}
		>
			<ListItem
				sx={{
					py: 2,
					pl: 4.5,
					background: (theme) => theme.palette.secondary.main,
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<ListItemIcon>
					<AccountCircleIcon
						fontSize='medium'
						sx={{
							color: (theme) => theme.palette.white?.main,
						}}
					/>
				</ListItemIcon>
				<ListItemText
					primary={`${t('hello')}, ${user?.name} ${user?.lastName}`}
					primaryTypographyProps={{
						variant: 'ExplicationTextBold',
						fontSize: '14px',
						color: 'white.main',
					}}
				/>
			</ListItem>
			<Divider />
			{RenderMenu}
		</List>
	);
	const RenderMobile = (
		<Accordion
			style={{
				borderRadius: '10px',
				maxWidth: '100%',
			}}
			expanded={expanded}
			onChange={() => setExpanded(!expanded)}
			sx={{
				width: '100%',
				background: (theme) => theme.palette.white?.light || 'white',
				'&::before': {
					display: 'none',
				},
				display: {
					md: 'none',
					xs: 'block',
				},
				position: 'relative',
			}}
		>
			<AccordionSummary
				expandIcon={
					<ExpandMoreIcon
						sx={{
							color: (theme) => theme.palette.white?.main || 'white',
						}}
					/>
				}
				aria-controls='panel1-content'
				id='panel1-header'
				sx={{
					width: '100%',
					background: (theme) => theme.palette.secondary?.main,
					borderRadius: '10px',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<AccountCircleIcon
					fontSize='medium'
					sx={{
						color: (theme) => theme.palette.white?.main,
					}}
				/>
				<Typography
					variant='ExplicationTextBold'
					color='white.main'
					ml={2}
					fontSize='14px'
				>
					{`${t('hello')}, ${user?.name} ${user?.lastName}`}
				</Typography>
			</AccordionSummary>
			<AccordionDetails
				sx={{
					width: '94%',
					borderBottomRightRadius: '10px !important',
					borderBottomLeftRadius: '10px !important',
					padding: 0,
					position: 'fixed',
					zIndex: 99,
					boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
					backdropFilter: 'blur(10px)',
					opacity: '0.97',
				}}
			>
				{RenderMenu}
			</AccordionDetails>
		</Accordion>
	);

	return (
		<Box>
			<Paper
				elevation={3}
				sx={{
					width: '100%',
					borderRadius: 3,
					overflow: 'hidden',
				}}
			>
				{RenderDesktop}
				{RenderMobile}
			</Paper>
		</Box>
	);
}
