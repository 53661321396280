'use client';
import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, useMediaQuery } from '@mui/material';
import { useClientTranslation } from '@negotium/common';
import { IPortraitCreate } from '@negotium/models';
import { useNotification } from '../ErrorContext';
import { getPortraitsActives } from '@negotium/libCustomer';
import { ImageWithFallback } from '../ImageWithFallback';
import Slider from 'react-slick';

type HeroProps = {
	lng: 'es' | 'en';
};

export const Hero = ({ lng }: HeroProps) => {
	const { t } = useClientTranslation(lng, ['common']);

	const [portraits, setPortraits] = useState<Array<IPortraitCreate>>([]);
	const isMobile = useMediaQuery('(max-width:768px)');

	const [loadingPortraits, setLoadingPortraits] = useState<boolean>(true);
	const { setErrorNotification } = useNotification();

	useEffect(() => {
		if (loadingPortraits) {
			getPortraitsActives()
				.then((response) => {
					setPortraits(response.docs);
					setLoadingPortraits(false);
				})
				.catch(() => {
					setLoadingPortraits(false);
					setErrorNotification(t('errorGetPortraits'));
				});
		}
	}, [loadingPortraits]);

	if (portraits.length === 0) {
		return null;
	}

	const settings = {
		dots: portraits.length > 1,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		infinite: portraits.length > 1,
		speed: 3000,
		autoplaySpeed: 3000,
		autoplay: portraits.length > 1,
	};

	return (
		<Container maxWidth={false} disableGutters>
			<Grid container>
				<Grid item xs={12}>
					<Box
						sx={{
							position: 'relative',
							boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.4)',
						}}
					>
						{/* @ts-expect-error Server Component  */}
						<Slider {...settings}>
							{[...portraits].map((portrait) => {
								return (
									<Box
										key={portrait.portraitId}
										width={{ xs: '100%', md: '100%' }}
										position='relative'
										display='flex'
										sx={{
											display: 'flex',
										}}
									>
										<ImageWithFallback
											src={
												isMobile
													? portrait.imageMobile?.url || ''
													: portrait.image?.url || ''
											}
											fallbackImage='/static/favicon/256x256.png'
											alt={portrait.title[lng]}
											width={portrait.image?.width}
											height={portrait.image?.height}
											style={{
												width: '100%',
												height: 'auto',
												display: 'flex',
											}}
										/>
									</Box>
								);
							})}
						</Slider>
					</Box>
				</Grid>
			</Grid>
		</Container>
	);
};
